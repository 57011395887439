label {
  &.error {
    color: $error;
    font-size: 12px;
  }
}

.log {
  display: flex;
  justify-content: center;
  align-items: center;
  .card-wrapper {
    text-align: center;
    // position: absolute;
    // top: 40%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
    min-width: 500px;

    .ant-card-body {
      padding: 74px;
    }

    @include media('<md') {
      min-width: 320px;
      .ant-card-body {
        padding: 34px;
        .login-logo {
          max-width: 150px !important;
        }
        // .ant-card-meta-title {
        //   font-size: 16px;
        // }
      }
    }

    .ant-card-head-title {
      font-size: 18px;
      font-weight: 700;
      // color: #ffffff;
      color: #2c2638;
      font-weight: 100;
    }
  }
}

.custom-modal {
  width: 550px !important;
  max-height: fit-content;
  margin: 3rem !important;
}

.print-input {
  background: white;
  margin: 0;
  padding: 0.2rem 1rem;
}

.print-field {
  background: white;
  margin: 0;
  padding: 0.2rem 1rem;
  border-bottom: 2px solid #A0BFDD;
}

.print-header {
  text-transform: uppercase;
  padding: .3rem 1rem;
  background: #E1E8F3;
  color: #787F8A;
  margin: 0;
  border-bottom: 2px solid #A0BFDD;
}

.dashboard {
  height: auto;

  .card-wrapper {
    display: flex;
    margin-bottom: 2rem;

    .ant-card {
      width: 50%;

      @include media('<lg') {
        width: 100%;
      }

      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }
}

.ant-form-item-explain div {
  float: left;
}

.ant-form-item.upload-wrapper.right {
  .ant-form-item-control {
    flex-direction: row;
    justify-content: flex-start;
  }
  .ant-upload-list-picture-card-container,
  .ant-upload.ant-upload-select-picture-card {
    margin: 0;
  }
}

.modal {
  .ant-form-item.upload-wrapper.right {
    .ant-form-item-control {
      justify-content: center;
    }
  }
}

.ant-card {
  border-radius: $table-border-radius;
  box-shadow: $table-box-shadow;

  &.login-header {
    .ant-card-head {
      color: #2c2638;
      background-color: #ffffff;
    }
  }
}

.ant-card-head {
  // background-color: #4f66a0;
  // color: #ffffff;
  // color: #2c2638;
  color: #ffffff;
  // background-color: #ffffff;
  background: $primary-color;
  text-transform: capitalize;
  border-radius: $table-border-radius-top;

  .ant-card-head-title {
    //added
    text-transform: initial;
  }
}

.ant-picker-large {
  padding: 5px 11px 6px;
}

.panel-heading .panel-title span {
  background-color: $primary-color !important;
}

.log {
  .card-wrapper {
    .ant-card-head-title {
      text-align: center;
    }
  }
}

.ant-card {
  &.profile-title {
    .ant-card-head {
      background-color: #848587;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 400px) {
  .ant-card-head {
    padding: 12px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
    font-size: 12px;
  }
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-left: 0px !important;
}

.ant-modal-body, .ant-modal-content {
  padding: 0 !important;
  border-radius: 1rem !important;
}
input,
select {
  font-size: 100% !important;
}
